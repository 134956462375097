var pageId = '';

pageId = document.querySelector('body').getAttribute('id');
switch (pageId) {
	//body id='top'
	case 'top':
		require('./top.ts');
	break;

	case 'location':
  $(function(){
    var myFlipster = $('.guide_sld_box');
    var num = $('.guide_map .num');
    var flip = $('.flipster__item');
    if ((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
      var style = 'carousel';
      var touch = 'touchend';
    }else {
      var style = 'coverflow';
      var touch = 'click';
    };
    myFlipster.flipster({
      style: style,
      touch: true,
      scrollwheel: false,
      loop: true,
      nav: true,
      spacing: -0.7,
      keyboard: true,
      start: 0,
    });
    $(num).on('click',function () {
      var index = $(this).attr("data-index") -1;
      $('.num').removeClass('active');
      $(this).addClass('active');
      console.log(index);
      setTimeout(function () {
        myFlipster.flipster('jump', index);
      }, 40);
    });

    $('.guide_sld_box').on(touch, function(){
      $('.num').removeClass('active');
      console.log('click');
      if ($('.flip1').hasClass('flipster__item--current')) {
        $('.num1').addClass('active');
      }else if ($('.flip2').hasClass('flipster__item--current')) {
        $('.num2').addClass('active');
      }else if ($('.flip3').hasClass('flipster__item--current')) {
        $('.num3').addClass('active');
      }else if ($('.flip4').hasClass('flipster__item--current')) {
        $('.num4').addClass('active');
      }else if ($('.flip5').hasClass('flipster__item--current')) {
        $('.num5').addClass('active');
      }else if ($('.flip6').hasClass('flipster__item--current')) {
        $('.num6').addClass('active');
      }else if ($('.flip7').hasClass('flipster__item--current')) {
        $('.num7').addClass('active');
      }else if ($('.flip8').hasClass('flipster__item--current')) {
        $('.num8').addClass('active');
      }else if ($('.flip9').hasClass('flipster__item--current')) {
        $('.num9').addClass('active');
      }else if ($('.flip10').hasClass('flipster__item--current')) {
        $('.num10').addClass('active');
      }else if ($('.flip11').hasClass('flipster__item--current')) {
        $('.num11').addClass('active');
      };
    });
  });
	break;

  case 'plan':
    var urlParam = location.search.substring(1);
    if(urlParam) {
    var param = urlParam.split('&');

    var paramArray = [];

    for (i = 0; i < param.length; i++) {
      var paramItem = param[i].split('=');
      paramArray[paramItem[0]] = paramItem[1];
    }

		if (paramArray.id == 'type_A') {
      $('.type_box').removeClass('show');
      $('#type_A').addClass('show');
    };
    if (paramArray.id == 'type_C') {
      $('.type_box').removeClass('show');
      $('#type_C').addClass('show');
    };
    if (paramArray.id == 'type_D') {
      $('.type_box').removeClass('show');
      $('#type_D').addClass('show');
    };
    if (paramArray.id == 'type_E') {
      $('.type_box').removeClass('show');
      $('#type_E').addClass('show');
    };
    if (paramArray.id == 'type_F') {
      $('.type_box').removeClass('show');
      $('#type_F').addClass('show');
    };
		if (paramArray.id == 'type_Fg') {
			$('.type_box').removeClass('show');
			$('#type_Fg').addClass('show');
		};
		if (paramArray.id == 'type_G') {
			$('.type_box').removeClass('show');
			$('#type_G').addClass('show');
		};
    if (paramArray.id == 'type_J') {
      $('.type_box').removeClass('show');
      $('#type_J').addClass('show');
    };
    if (paramArray.id == 'type_L') {
      $('.type_box').removeClass('show');
      $('#type_L').addClass('show');
    };
  };
	break;
};

//common
require('./anime.ts');


const nav = document.querySelectorAll('header nav li');
for (let i = 0; i < nav.length; i++) {
	if (location.href.match('/voice.php')) {
		document.querySelector('header nav li:nth-of-type(2)').classList.add('active');
	}else if (location.href.match('/zeh.php')) {
		document.querySelector('header nav li:nth-of-type(3)').classList.add('active');
	}else if (location.href.match('/concept.php')) {
		document.querySelector('header nav li:nth-of-type(4)').classList.add('active');
	}else if (location.href.match('/access.php')) {
		document.querySelector('header nav li:nth-of-type(5)').classList.add('active');
	}else if (location.href.match('/location.php')) {
		document.querySelector('header nav li:nth-of-type(6)').classList.add('active');
	} else if (location.href.match('/design.php')) {
			document.querySelector('header nav li:nth-of-type(7)').classList.add('active');
	} else if (location.href.match('/plan.php')) {
			document.querySelector('header nav li:nth-of-type(8)').classList.add('active');
	} else if (location.href.match('/type_A.php') ||
						 location.href.match('/type_C.php') ||
						 location.href.match('/type_D.php') ||
						 location.href.match('/type_E.php') ||
						 location.href.match('/type_Eg.php') ||
						 location.href.match('/type_F.php') ||
						 location.href.match('/type_F_menu2.php') ||
						 location.href.match('/type_Fg.php') ||
						 location.href.match('/type_G.php') ||
						 location.href.match('/type_Gg.php') ||
						 location.href.match('/type_I.php') ||
						 location.href.match('/type_J.php') ||
						 location.href.match('/type_J1.php') ||
						 location.href.match('/type_J1_menu3.php') ||
						 location.href.match('/type_L.php') ||
						 location.href.match('/type_M.php')) {
			document.querySelector('header nav li:nth-of-type(8)').classList.add('active');
	} else if (location.href.match('/quality.php')) {
			document.querySelector('header nav li:nth-of-type(9)').classList.add('active');
	} else if (location.href.match('/aftersupport.php')) {
			document.querySelector('header nav li:nth-of-type(10)').classList.add('active');
	} else if (location.href.match('/modelroom.php')) {
			document.querySelector('header nav li:nth-of-type(11)').classList.add('active');
	} else if (location.href.match('/brand.php')) {
			document.querySelector('header nav li:nth-of-type(12)').classList.add('active');
	} else {
			document.querySelector('header nav li:nth-of-type(1)').classList.add('active');
	}
};

window.addEventListener('load', function(){
  document.querySelector('body').classList.add('show');
});

const header = document.querySelector('header');
window.addEventListener('scroll',function(){
  var sH = window.scrollY;
  if(sH >= 100){
    header.classList.add('hScroll');
  }else{
    header.classList.remove('hScroll');
  }
});

const gBtn = document.querySelector('.g_menu_btn');
const gNav = document.querySelector('.g_nav');
gBtn.addEventListener('click',function(){
  this.classList.toggle('active');
  gNav.classList.toggle('active');
  document.querySelector('header').classList.toggle('navAct');
});

const pageChTrigger = document.querySelectorAll('a:not([href^="#"]):not([target]):not([href^="tel:"])');
for (let i = 0; i < pageChTrigger.length; i++){
  pageChTrigger[i].addEventListener('click', (event) => {
  event.preventDefault();
  const url = pageChTrigger[i].getAttribute('href');
  console.log(url);
  if (url !== '') {
    document.querySelector('body').classList.remove('show');
    setTimeout(() => {
      window.location = url;
    }, "1000")
  }
  return false;
  });
};
window.addEventListener("pageshow", function(event){
  if (event.persisted) {
    document.querySelector('body').classList.add('show');
  }
});


const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){
  smoothScrollTrigger[i].addEventListener('click', (event) => {
    event.preventDefault();
    let href = smoothScrollTrigger[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const gap = 0;
    const target = rect + offset - gap;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
}
